<template>
    <section class="obras-servicios-lista">
        <titulo-divisor titulo="Lista de chequeo" class="mb-4">
            <div class="d-flex icon-option">
                <el-tooltip placement="bottom" effect="light" visible-arrow popper-class="like-buttons">
                    <template #content>
                        <p v-for="check in list_check" :key="check.id" class="p-2 cr-pointer item" @click="openEditPredeterminada(check)">{{ check.lista_chequeo }}</p>
                    </template>
                    <div class="listado-chequeos text-general d-middle-center br-4 border-general w-100 px-2 my-auto">
                        <p class="f-12">Listas predeterminadas</p>
                        <div class="cant-chequeos bg-general f-12 ml-2 rounded-pill text-white text-center">{{ list_check.length || 0 }}</div>
                    </div>
                </el-tooltip>
                <el-tooltip content="Crear lista de chequeo" placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                    <i class="icon-plus-circle f-30 cr-pointer" @click="openCreateCheckList" />
                </el-tooltip>
            </div>
        </titulo-divisor>
        <section class="listado-grupos-chequeos overflow-auto custom-scroll">
            <div class="row justify-content-center mx-0">
                <div class="col-11 obras-vigentes-equipos-collapse">
                    <el-collapse v-model="activeNames">
                        <div v-for="(chequeo, indexChequeo) in listasChequeo" :key="indexChequeo" class="row">
                            <div class="col-11 mb-3">
                                <el-collapse-item :name="chequeo.id">
                                    <template slot="title">
                                        <div class="d-flex f-600 w-95 justify-content-between icon-option">
                                            <p class="text-general">{{ chequeo.nombre }}</p>
                                            <i class="icon-plus-box f-20" @click.stop="openModalCrearActividad(chequeo)"/>
                                        </div>
                                    </template>
                                    <div class="row">
                                        <div class="col-2 offset-5 mt-3">
                                            <p class="text-general text-center f-600">Peso de actividad</p>
                                        </div>
                                    </div>
                                    <draggable tag="div" :list="chequeo.actividades" class="border" handle=".handle">
                                        <div v-for="(actividad, indexActividad) in chequeo.actividades" :key="indexActividad"
                                            class="row px-4 py-2"
                                            :class="{'bg-f5' : indexActividad % 2 !== 0}"
                                        >
                                            <div class="col-1 d-middle text-muted">
                                                <i class="icon-menu handle f-16 cr-pointer mr-2" />
                                            </div>
                                            <div class="col-4 my-auto">
                                                <p>{{ actividad.actividad }}</p>
                                            </div>
                                            <div class="col-2 d-middle-center">
                                                <div class="border br-3 px-3 py-1 bg-f5">
                                                    <p class="f-13 text-muted">{{ actividad.peso }}</p>
                                                </div>
                                                <p class="ml-2">%</p>
                                            </div>
                                            <div class="col-3">
                                                <div class="d-middle-center text-muted">
                                                    <div class="adj-item position-relative mx-2">
                                                        <div v-if="cantAdjuntos(actividad.adjuntos, 1)" class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ cantAdjuntos(actividad.adjuntos, 1) }}</div>
                                                        <i class="icon-pdf-file f-18" @click="openModalPDF(actividad)" />
                                                    </div>
                                                    <div class="adj-item position-relative mx-2">
                                                        <div v-if="cantAdjuntos(actividad.adjuntos, 3)" class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ cantAdjuntos(actividad.adjuntos, 3) }}</div>
                                                        <i class="icon-video f-18" @click="openModalVideo(actividad)" />
                                                    </div>
                                                    <div class="adj-item position-relative mx-2">
                                                        <div v-if="cantAdjuntos(actividad.adjuntos, 2)">
                                                            <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ cantAdjuntos(actividad.adjuntos, 2) }}</div>
                                                            <i class="icon-image-box f-18" @click="openModalGallery(actividad)" />
                                                        </div>
                                                        <i v-else class="icon-image-box f-18" />
                                                    </div>
                                                    <div class="adj-item position-relative mx-2">
                                                        <div  v-if="cantAdjuntos(actividad.adjuntos, 4)" class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ cantAdjuntos(actividad.adjuntos, 4) }}</div>
                                                        <i class="icon-folder-line f-18" @click="openModalAllFiles(actividad)" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 d-middle justify-content-end icon-option text-muted">
                                                <i class="icon-pencil f-20 cr-pointer" @click="openModalEditarActividad(actividad)" />
                                                <i class="icon-trash-can-outline f-20 cr-pointer" @click="openModalEliminarActividad(actividad)" />
                                                <i class="icon-paperclip f-20 cr-pointer" @click="openModalSubirArvhivos(actividad)" />
                                            </div>
                                        </div>
                                    </draggable>
                                </el-collapse-item>
                            </div>
                            <div class="mt-2 icon-option text-muted">
                                <i class="icon-pencil f-20 cr-pointer" @click="openEditCheckList(chequeo)" />
                                <i class="icon-trash-can-outline f-20 cr-pointer" @click="openModalEliminarLista(chequeo)" />
                            </div>
                        </div>
                    </el-collapse>
                </div>
            </div>
        </section>
        <!-- partials -->
        <modal-crear-lista-chequeo ref="crearListaChequeo" @crear="crearListaChequeo" @editar="editarListaChequeo" />
        <modal-crear-actividad ref="crearActividad" @crearActividad="crearActividad" />
        <modal-editar-actividad ref="modalEditarActividad" @editarActividad="editarActividad" />
        <modal-subir-archivos ref="modalSubirArchivos" @subirArchivos="subirArchivos" />
        <modal-all-files ref="modalAllFiles" />
        <modal-eliminar ref="modalEliminarLista" 
            title="Eliminar lista de chequeo" 
            mensaje="¿Está seguro que quiere eliminar esta lista de chequeo?"
            @delete="deleteLista"
        />
        <modal-eliminar ref="modalEliminarActividad" 
            title="Eliminar Actividad" 
            mensaje="¿Está seguro que quiere eliminar esta actividad?"
            @delete="deleteActividad"
        />
        <modal-image-gallery ref="openModalGallery" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Aws from '~/services/aws'
export default {
    components:{
        modalAllFiles: () => import('../partials/modalAllFiles.vue'),
        modalCrearActividad: () => import('../partials/modalCrearActividad.vue'),
        modalSubirArchivos: () => import('../partials/modalSubirAchivosActividad.vue'),
        modalEditarActividad: () => import('../partials/modalEditarActividad.vue'),
        modalCrearListaChequeo: () => import('../partials/modalCrearListaChequeo.vue'),
    },
    data(){
        return{
            id_proyecto: this.$route.params.id_proyecto,
            id_control_piso: this.$route.params.id_control_piso,
            activeNames: [],
            chequeoSelect: {},
            actividadSelect: {},
        }
    },
    computed: {
        ...mapGetters({
            list_check: 'selects/selects/list_check',
            listasChequeo: 'obras/servicios/lista_chequeo/listasChequeo',
            detalleServicio: 'obras/servicios/lista_chequeo/detalleServicio',
        }),
    },
    async created () {
        await this.Action_get_selects_list_check()
        await this.listar()
    },
    methods:{
        ...mapActions({
            Action_get_selects_list_check: 'selects/selects/Action_get_selects_list_check',
            Action_get_listas_chequeo: 'obras/servicios/lista_chequeo/Action_get_listas_chequeo',
            Action_create_lista_chequeo: 'obras/servicios/lista_chequeo/Action_create_lista_chequeo',
            Action_edit_lista_chequeo: 'obras/servicios/lista_chequeo/Action_edit_lista_chequeo',
            Action_delete_lista_chequeo: 'obras/servicios/lista_chequeo/Action_delete_lista_chequeo',
            Action_add_actividad: 'obras/servicios/lista_chequeo/Action_add_actividad',
            Action_edit_actividad: 'obras/servicios/lista_chequeo/Action_edit_actividad',
            Action_delete_actividad: 'obras/servicios/lista_chequeo/Action_delete_actividad',
            Action_add_adjuntos_actividad: 'obras/servicios/lista_chequeo/Action_add_adjuntos_actividad',
        }),
        async listar(){
            await this.Action_get_listas_chequeo({id_proyecto: this.id_proyecto, id_control_piso: this.id_control_piso})
        },

        //Crear lista de chequeo
        openCreateCheckList(){
            this.$refs.crearListaChequeo.toggle()
        },
        async crearListaChequeo(payload){
            try {
                const params = {
                    id_proyecto: this.id_proyecto,
                    id_control_piso: this.id_control_piso,
                    payload
                }
                await this.Action_create_lista_chequeo(params)
                await this.listar()
            } catch (e) {
                console.error(e);
            }
        },
 
        //Editar lista de chequeo predeterminada
        openEditPredeterminada(chequeo){
            this.$refs.crearListaChequeo.editPredeterminada(chequeo)
        },
        //Editar lista de chequeo
        openEditCheckList(chequeo){
            this.$refs.crearListaChequeo.edit(chequeo)
        },
        async editarListaChequeo({id_configuracion_control_piso_lista_chequeo, ...payload}){
            try {
                const params = {
                    id_proyecto: this.id_proyecto,
                    id_control_piso: this.id_control_piso,
                    id_control_pisos_lista_chequeo: id_configuracion_control_piso_lista_chequeo,
                    payload
                }
                await this.Action_edit_lista_chequeo(params)
                await this.listar()
            } catch (e) {
                console.error(e);
            }
        },

        //Eliminar actividad
        openModalEliminarLista(chequeo){
            this.chequeoSelect = chequeo
            this.$refs.modalEliminarLista.toggle()
        },
        async deleteLista(){
            const payload = {
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_control_pisos_lista_chequeo: this.chequeoSelect.id,
            }
            await this.Action_delete_lista_chequeo(payload)
        },

        //Crear actividad
        openModalCrearActividad(chequeo){
            this.chequeoSelect = chequeo
            this.$refs.crearActividad.toggle()
        },
        async crearActividad(nombre){
            const params = {
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_control_pisos_lista_chequeo: this.chequeoSelect.id,
                payload: {nombre}
            }
            await this.Action_add_actividad(params)
            await this.listar()
        },

        //Editar actividad
        openModalEditarActividad(actividad){
            this.$refs.modalEditarActividad.toggle(actividad)
        },
        async editarActividad(params){
            params = {
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                ...params
            }
            await this.Action_edit_actividad(params)
            await this.listar()
        },

        //Eliminar actividad
        openModalEliminarActividad(actividad){
            this.actividadSelect = actividad
            this.$refs.modalEliminarActividad.toggle()
        },
        async deleteActividad(){
            const payload = {
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_control_pisos_lista_chequeo: this.actividadSelect.id_lista_chequeo,
                id_configuracion_control_piso_lista_chequeo_actividades: this.actividadSelect.id
            }
            await this.Action_delete_actividad(payload)
        },

        //Subir adjuntos a una actividad
        openModalSubirArvhivos(actividad){
            this.actividadSelect = actividad
            this.$refs.modalSubirArchivos.toggle()
        },
        async subirArchivos(files){
            console.log("🚀 ~ file: listaChequeo.vue:268 ~ subirArchivos ~ files:", files)
            this.$try(async () => {
                let adjunto = []
				let nombre_archivo = []
                if (files.length) {
					nombre_archivo = files.map(e => e.name)
                    const promesas = files.map(e => Aws.uploadFile({file: e, path: 'servicios'}))
                    adjunto = await this.resolverPromise(promesas)
                }
                const params = {
                    id_proyecto: this.id_proyecto,
                    id_control_piso: this.id_control_piso,
                    id_control_pisos_lista_chequeo: this.actividadSelect.id_lista_chequeo,
                    id_configuracion_control_piso_lista_chequeo_actividades: this.actividadSelect.id,
                    payload: {adjunto:adjunto, nombre_archivo:nombre_archivo}
                }
                await this.Action_add_adjuntos_actividad(params)
                await this.listar()
            });
        },
        async resolverPromise(promesas){
            const promises = await Promise.allSettled(promesas)
            return promises.map(e => {
                if (e.status === 'fulfilled') return e.value
            })
        },

        //Visualizar los adjuntos
		openModalPDF(actividad){
            this.$refs.modalAllFiles.toggle({...actividad}, this.detalleServicio.seccion, 'pdf')
        },
        openModalVideo(actividad){
            this.$refs.modalAllFiles.toggle({...actividad}, this.detalleServicio.seccion, 'video')
        },
        openModalGallery(actividad){
            const imagenes = []
            actividad.adjuntos.forEach(el => {
                if (el.id_tipo == 2) imagenes.push(el.archivo)
            })
            this.$refs.openModalGallery.toggle(imagenes, actividad.actividad)
        },
        openModalAllFiles(actividad){
            this.$refs.modalAllFiles.toggle({...actividad}, this.detalleServicio.seccion)
        },

        
        cantAdjuntos(adjuntos, tipo){
            let cant = 0
            adjuntos.forEach( el => cant += (el.id_tipo === tipo ? 1 : 0))
            return cant
        }
    }

}
</script>
<style lang="scss" scoped>
    .obras-servicios-lista{
        .listado-chequeos{
            width: 148px;
            height: 32px;
        .cant-chequeos{
            width: 27px;
            height: 20px;
        }
    }
    .w-95{
        width: 95%;
    }
    .listado-grupos-chequeos{
        height: calc(100vh - 460px);
    }
    .adj-item{
        .cant-adj{
            position: absolute;
            background-color: #B53438;
            top: -4px;
            right: -9px;
            z-index: 1;
        }
    }
    p{
        cursor: default;
    }
    .icon-menu{
        cursor: grab;
    }
}
</style>